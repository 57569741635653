'use client'

import { useLocale } from 'next-intl'

import type { HomeAnnouncement } from '~/components/home/home-announcement'
import { Typo, type TypoProps } from '~/components/typo'

interface HomeAnnouncementDateProps
  extends TypoProps,
    Pick<HomeAnnouncement, 'timestamp'> {}

export const HomeAnnouncementDate: React.FC<HomeAnnouncementDateProps> = ({
  timestamp,
  ...props
}) => {
  const locale = useLocale()

  const formattedDate = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  }).format(timestamp)

  return <Typo {...props}>{formattedDate}</Typo>
}
